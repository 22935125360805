// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rs d_fn d_bF d_dt";
export var alignLeft = "s_qF d_fn d_bF d_dt";
export var alignDiscCenter = "s_rt d_fp d_bC d_dv";
export var alignCenter = "s_bN d_fp d_bC d_dv";
export var alignDiscRight = "s_rv d_fq d_bG d_dw";
export var alignRight = "s_qG d_fq d_bG d_dw";
export var footerContainer = "s_rw d_dV d_bV";
export var footerContainerFull = "s_rx d_dS d_bV";
export var footerHeader = "s_kc d_kc";
export var footerTextWrapper = "s_ry d_v";
export var footerDisclaimerWrapper = "s_kk d_kk d_cj";
export var badgeWrapper = "s_rz d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "s_rB undefined";
export var footerDisclaimerLeft = "s_rC undefined";
export var verticalTop = "s_rD d_by d_bH d_bM d_bK";
export var firstWide = "s_rF";
export var disclaimer = "s_rG d_by d_bH";
export var socialDisclaimer = "s_rH";
export var left = "s_rJ";
export var wide = "s_rK d_cx";
export var right = "s_rL d_bJ";
export var line = "s_fj d_fk d_ct";
export var badgeDisclaimer = "s_rM d_bB d_bN d_bH";
export var badgeContainer = "s_rN d_by d_bG d_bN";
export var badge = "s_rP";
export var padding = "s_rQ d_c6";
export var end = "s_rR d_bG";
export var linkWrapper = "s_rS d_dz";
export var link = "s_mz d_dz";
export var colWrapper = "s_rT d_cw";
export var consent = "s_g d_g d_bB d_bN";
export var media = "s_rV d_bw d_dx";
export var itemRight = "s_rW";
export var itemLeft = "s_rX";
export var itemCenter = "s_rY";
export var exceptionWeight = "s_rZ y_s6";