// extracted by mini-css-extract-plugin
export var alignLeft = "r_qF d_fn d_bF d_dt";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignRight = "r_qG d_fq d_bG d_dw";
export var FAQMainHeader = "r_ks d_ks d_v d_bx";
export var FAQComponentHeader = "r_ky d_ky d_bw";
export var FAQComponentParagraph = "r_kt d_kt d_cC";
export var FAQComponentsWrapper = "r_kq d_kq d_cm d_cK d_cQ d_c1 d_cW d_dz";
export var FAQComponentsWrapperClean = "r_kr d_kr d_cm d_cK d_cQ d_cZ d_dz";
export var accordion__body = "r_kz d_kz d_bx";
export var accordion__bodyHidden = "r_kC d_kC d_bv d_dk";
export var FAQDivider = "r_kp d_kp";
export var accordionWrapper = "r_rq d_cf";
export var accordion__item = "r_rr d_cK";
export var accordion_arrow = "r_kG d_kG";
export var accordion_arrow_open = "r_kJ d_kJ";
export var accordion_arrow_2 = "r_kL d_kL";
export var accordion_arrow_2_open = "r_kN d_kN";